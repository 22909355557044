/* corporate Mmebership Registration constants */

export const STATIC_RENEWAL_PERIOD = 90;
export const TOTAL_CONTACTS = 'ALL CONTACTS';
export const TOTAL_MEMBERS = 'TOTAL MEMBERS';
export const NEEDS_RENEWAL = 'NEEDS RENEWAL';
export const ADMINS = 'ADMINS';
export const TOTAL_PURCHASED_SLOTS = 'TOTAL PURCHASED SLOTS';
export const TOTAL_FREE_SLOTS = 'TOTAL FREE SLOTS';
export const STATISTICS_ALERT = 'STATISTICS ALERT';
export const ADD_CONTACT_BTN = 'ADD CONTACT BTN';
export const UNASSIGNED_MEMBERS_LABEL = 'Unassigned Contacts';
export const IN_GOOD_STANDING = 'In Good Standing';
export const IN_RENEWAL_PERIOD = 'Renewal Required';
export const MANAGE_CONTACTS = "Manage Contacts";
export const EXPIRED = 'Expired';
export const NOT_A_MEMBER = 'Not a Member';
// Profile Page Views (Step-2).
export const SIGN_UP_VIEW = 'SIGN_UP_VIEW';
export const SIGN_IN_VIEW = 'SIGN_IN_VIEW';
export const PROFILE_FORM_VIEW = 'PROFILE_FORM_VIEW';
export const STAFF_CONTACTS_VIEW = 'STAFF_CONTACTS_VIEW';

// Table Columns
export const NAME = 'NAME';
export const ROLES = 'ROLES';
export const ACCOUNT_TYPE = 'ACCOUNT_TYPE';
export const ACTIONS = 'ACTIONS';
export const PAST_MEMBER_TYPE = 'PAST_MEMBER_TYPE';
export const MEMBER_TYPE = 'MEMBER_TYPE';
export const REGISTRATION_TYPE = 'REGISTRATION_TYPE';

export const FROM_ADDITIONAL_SLOT = 'from_additional_slot';
export const FROM_COMPANY_CONTACT = 'from_company_contact';
export const EDIT_COMPANY_CONTACT = 'edit_company_contact'

export const CorpMembershipStatisticsInitialState = {
  [TOTAL_CONTACTS]: { key: TOTAL_CONTACTS, count: 0, label: 'All Contacts', link: true, linkLabel: 'Add Contact' },
  [TOTAL_MEMBERS]: { key: TOTAL_MEMBERS, count: 0, label: 'Total Members', link: false },
  [NEEDS_RENEWAL]: { key: NEEDS_RENEWAL, count: 0, label: 'Needs Renewal', link: true, linkLabel: 'Renew' },
  divider: { key: 'divider', dividerDisplay: true },
  [TOTAL_PURCHASED_SLOTS]: { key: TOTAL_PURCHASED_SLOTS, count: 0, label: 'Total Purchased Slots', link: false },
  [TOTAL_FREE_SLOTS]: { key: TOTAL_FREE_SLOTS, count: 0, label: 'Total Free Slots', link: false },
  // [TOTAL_PURCHASED_SLOTS]: { key: TOTAL_PURCHASED_SLOTS, purchaseSlotsCount: 0, link: false, freeSlotscount: 0, label: 'Total Free Slots', link: false, progressBar: true },
}

export const membersTableTemplate = {
  title: '',
  dataSource: [],
  columns: [],
  tags: [],
  id: null,
  showTableActions: true,
  showPurchaseSlot: true,
  showAssignUser: true,
  freeSlots: null,
}
export const UN_ASSIGNED_GROUP = { group_name: "No Membership", groupid: -1 };
export const membershipStepInitialState = {
  itemstoadd: {},
  currentStep: 0,
  additionalInfo: [],
  members: [],
  cartUpdated: false,
  totalAdditionalCost: 0
}

export const getPopupContainer = (triggerNode) => (triggerNode?.parentNode) || document.body;
export const publicMembershipStepsDisableJoinRenew = [
  {
    blurb: "",
    button: "Start",
    menu_order: 1,
    screentype: "start"
  }
]

export const publicMembershipSteps = [
  {
    blurb: "",
    button: "Start",
    menu_order: 1,
    screentype: "start"
  },
  {
    blurb: "Select Membership Type",
    button: "Join/Renew",
    menu_order: 2,
    screentype: "submenu"
  },
  {
    blurb: "Update Company Profile",
    button: "Profile",
    menu_order: 3,
    screentype: "contactinfo"
  },
  {
    blurb: "Add Members",
    button: "Individual Members",
    menu_order: 4,
    screentype: "additionalslots"
  },
  {
    blurb: "Review and Checkout",
    button: "Review",
    menu_order: 5,
    screentype: "review"
  },
]

/* Exhibitor Registration constants */


export const newAttendeeFields = [
  { label: "First Name", key: 'firstname', required: 1 },
  { label: "Last Name", key: 'lastname', required: 1 },
  { label: "Email", key: 'email', required: 1 },
  { label: "City", key: 'city', required: 0 },
  { label: "State/Province", key: 'state', required: 0 },
  { label: "Country", key: 'country', required: 0 },
  { label: "Company", key: 'company', required: 0 },
]

export const removalOptions = [
  { key: 0, label: 'Remove From Group & Delete Registration', allowCompanyRemoval: true },
  { key: 1, label: 'Remove From Group & Keep Registration', allowCompanyRemoval: true },
  { key: 2, label: 'Remove Registration & Keep in Group', allowCompanyRemoval: false },
]
export const DELETE_REG_AND_GROUP = "delete_reg_and_group";
export const DELETE_GROUP_KEEP_REG = "delete_group_keep_reg";
export const DELETE_REG_KEEP_GROUP = "delete_reg_keep_group";

